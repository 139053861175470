
h1{
    font-weight: 600;
    text-transform: uppercase;
  }
  h2{
    font-weight: 400;
  }
  h3{
    font-weight: 600;
    text-transform: uppercase;
  }
  h4{
    font-weight: 600;
  }
  h5{
    font-size: 14px;
    font-weight: 600;
  }
  h6{
    font-size: 12px;
    font-weight: 600;
  }
 
  b, strong, dt{
    font-weight: 600;
  }
  
  .btn{
    font-weight: 600;
  }
  .btn-sm{
    padding: 2px 5px;
    font-size: 12px;
  }
  
  ul.notype{
    list-style-type: none;
    padding-left: 0;
    margin: 0;
  }
  ul.notype > li{
    margin-bottom: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid #dee2e6;
  }
  ul.notype > li:last-child{
    border-bottom: 0; 
    margin-bottom: 0;
  }
  
  ul,
  ol{
    margin: 0;
    padding: 0;
  }
  ul.bordered > li,
  ol.bordered > li{
    border-bottom: 1px solid #dee2e6;
    padding: 8px 0;
  }
  ul.bordered > li:first-child,
  ol.bordered > li:first-child{
    padding-top: 0;
  }
  ul.bordered > li:last-child,
  ol.bordered > li:last-child{
    border-bottom: 0;
    padding-bottom: 0;
  }
  
  
  .table{
    margin-bottom: 0;
  }
  .table td, .table th {
    padding: 10px;
  }
  
  .rightbar {
    position: sticky;
    top: 170px;
    z-index: 100;
    overflow-x: hidden;
    overflow-y: auto;
  }
  
  .box-one{
    background-color: #fff;
    border: 1px solid #E0E0E0;
    border-radius: 5px;
    box-shadow: 0 0 3px rgba(44, 44, 44, 0.1);
    margin-bottom: 10px;
  }
  .box-one > h6, 
  .box-one > .row > [class^="col"] > h6{
    background-color: #fff;
    border-bottom: 1px solid #E0E0E0;
    border-radius: 5px 5px 0 0;
    padding: 10px;
    margin: 0;
  }
  .box-one > h5,
  .box-one > .row > [class^="col"] > h5{
    padding: 10px;
    margin: 0;
  }
  
  .header-contents{
    font-weight: normal;
  }
  .box-one > h6 .header-contents,
  .box-one > .row > [class^="col"] > h6 .header-contents{
    float: right;
  }
  .box-one > h6 .header-contents .form-control,
  .box-one > .row > [class^="col"] > h6 .header-contents .form-control{
    display: inline;
    width: 100px;
    height: 30px;
    padding: 2px 5px;
    font-size: inherit;
    margin: -5px 0;
  }
  
  .box-two{
    background-color: #fff;
    border: 1px solid #E0E0E0;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  .box-two > h6,
  .box-two > .row > [class^="col"] > h6{
    height: 36px;
    line-height: 36px;
    background-color: #F2F2F2;
    border-bottom: 1px solid #E0E0E0;
    border-radius: 5px 5px 0 0;
    padding: 0 10px;
    margin: 0;
  }
  .pad_left_Rght_Top_Bottom_0{
    padding: 0 0 0 0;
  }
  .pad_Left_Top_Bottom_10{
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
  }
  .box-contents{
    padding: 10px 10px;
  }
  .box-contents a{
    font-weight: 600;
  }
  
  .content-row{
    display: block;
    width: 100%;
    border-bottom: 1px solid #E0E0E0;
    padding: 8px 0;
  }
  .content-row:first-child{
    padding-top: 0;
  }
  .content-row:last-child{
    border-bottom:0;
    padding-bottom: 0;
  }
  .content-row h6{
    display: inline-block;
    margin: 0 6px 0 0;
    padding: 0;
  }
  .content-row span{
    display: inline-block;
  }
  .content-row .btn{
    margin-top: -3px;
  }
  
  .rotate{
    animation: rotate 1.5s linear infinite; 
  }
  @keyframes rotate{
    to{ transform: rotate(360deg); }
  }
  @media print {
    #printPageButton {
      display: none;
    }
  }
  @media print {
    #candidateNavBar {
      display: none;
    }
  }
  @media print {
    #main_block{
      width: 100% !important;
      max-width :100% !important;
      flex: unset;
      border-right: none !important;
    }
    #hide_Block{
      display: none;
    }
  }
  button#applyForRetotalingBtn {
    background: none;
    color: #0069d9;
    border: none;
}

.blur-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); 
  backdrop-filter: blur(8px); 
  z-index: 9999;
}

.custom-box-shadow {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1), inset 0 -2px 0 #dfe1e2;
}

.mb260{
  margin-bottom: -260px;
}