.nav-tabs {
  border-bottom: none;
}
.nav-tabs .nav-link {
  height: 100%;
}

.main-nav-tab .nav-link{
  padding-top: 0;
  padding-bottom: 0;
  height: 50px;
  line-height: 50px;
  }

.bottom-shadow{
  background: #F8FAFC 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 5px #0000000D;
  opacity: 1;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: #067FD0;
  background-color: #fff;
  border: none;
  border-color: none;
}

.top-nav .nav-link.active{
  border-bottom: 2px #201542 solid;
  color: #201542; 
}

.nav-item {
  text-align: center;
  margin-left: 0px;
  margin-right: 0px;
}

.help{
  height: 50px;
  line-height: 50px;
  border: 1px solid rgb(184, 182, 182);
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: white;
  text-align: left;
  padding-left: 5px;
  border-radius: 2px;
}

@media screen and (max-width : 1240px) and (min-width : 778px){
  .main-nav-tab .nav-link{
        line-height: 30px;
        height: 60px;
  }
}
@media screen and (max-width : 777px) and (min-width : 768px){
  .main-nav-tab .nav-link{
        line-height: 30px;
        height: 80px;
  }
}

.f10 {
  font-size: 10px;
}

.f15 {
  font-size: 15px;
}

.f16 {
  font-size: 16px;
}

.f18 {
  font-size: 18px;
}

.candidateHomeTabCss {
  margin: 20px;
  padding-bottom: 50px;
}

.mpscHelpLineDivCss {
  height: 290px;
  margin-top: 40px;
  background-color: white;
  padding: 15px;
}

.mpscHelpLineParaCss {
  border-bottom: 1px solid gray;
  border-color: #0057C1;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 10px;
}

.ml7 {
  margin-left: 7px;
}

.w5 {
  width: 5%;
}

.w3 {
  width: 3%;
}

.w30 {
  width: 30%;
}

.w80 {
  width: 80%;
}

.mt45 {
  margin-top: 45px;
}

.mt5 {
  margin-top: -5px;
}

.mt50 {
  margin-top: 50px;
}

.lh30 {
  line-height: 30px;
}

.pr100 {
  padding-right: 100px;
}

.ml56 {
  margin-left: 56px;
} 

.marksheetModalCss {
  width: 100%;
  cursor: pointer;
}

.downloadMarksheetTemplateCss {
  width: 100%;
  height: 400px;
}

.mt21 {
  margin-top: -21px;
}

.w8 {
  width: 8%;
}

.w13 {
  width: 13%;
}

.w15 {
  width: 15%;
}

.w27 {
  width: 27%;
} 

.w16 {
  width: 16%;
}

.w425 {
  max-width: 425px;
}

.ml40 {
  margin-left: 40px;
}

.mb100 {
  margin-bottom: 100px;
}

.mb50 {
  margin-bottom: 50px;
}

.forgotPassTabCss {
  background-color: #ffff;
  padding: 30px;
  padding-left: 100px;
  padding-right: 100px;
}

.top12 {
  top: 12px;
}

.right30 {
  right: 30px;
}

.mt36 {
  margin-top: 36px;
}

.blackColor{
  color: '#343a40'
}

.textDecorationCss{
  text-decoration: none;
}

.textDecorationCss:hover {
  text-decoration: underline; 
}