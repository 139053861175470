body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.form-control {
  font-size: 13px;
  font-weight: normal;
}

.form-select {
  font-size: 13px;
  font-weight: normal;
}

h5{
  font-size: 14px;
  font-weight: 600;
}

.bg-profile-tabs{
  background-color: #F2F2F2;
}

.edit-icon {
  color: #007bff; 
  font-size: 1rem; 
  transition: color 0.3s ease;
}

.edit-icon:hover {
  color: #004494;
  cursor: pointer;
}

.custom-modal-header{
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  justify-content: space-between;
}

.custom-modal-header .close {
  color: #495057;
}

input[type="radio"],
input[type="checkbox"] {
  border: 1px solid #495057;
}

.payment-modal-header{
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  justify-content: space-between;
}

.input-group-text{
  padding: 0;
}

.border_radius{
  border-radius: inherit;
}